import React, { useEffect, useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import { styled } from "@mui/material/styles"
import LandingPage from "LandingPageMaterial/Layout/LandingPage"
import GridContainer from "components/material-kit-pro-react/components/Grid/GridContainer.js"
import GridItem from "components/material-kit-pro-react/components/Grid/GridItem.js"
import Parallax from "components/material-kit-pro-react/components/Parallax/Parallax.js"
import SectionTemplate from "LandingPageMaterial/Views/Sections/SectionTemplate"
import TabSimple from "components/Core/Tabs/TabSimple"
import sectionStyle from "LandingPageMaterial/Views/Sections/sectionStyle"
import SectionCoberturas from "LandingPageMaterial/Views/Products/SectionCoberturas"
import InfoArea from "components/material-kit-pro-react/components/InfoArea/InfoAreaLanding.js"
import { Typography } from "@mui/material"

const NewDiv = styled("div")(({ theme }) => ({
  ...sectionStyle.p,
}))

const InformacionCorporativaTemplate = ({ data }) => {
  // const insuranceCompany = process.env.GATSBY_INSURANCE_COMPANY
  const seccionsTitle = {
    QSOM: "¿Quiénes Somos?",
    OFIC_PRE: "Red de Oficinas Comerciales",
  }

  const seccionesObj = data?.allStrapiSeccionesInfoCorporativas?.nodes
  const informacionObj = data?.allStrapiInformacionCorporativas?.edges

  const selectedNodeId =
    typeof window !== "undefined"
      ? window?.location?.search?.split("=")?.at(1)
      : null

  const selectedInfo = informacionObj?.find(
    ({ node }) => node?.codigo === selectedNodeId
  )
  const selectedSeccion = seccionesObj?.filter(
    (node) => node?.informacion_corporativa?.codigo === selectedNodeId
  )

  function tabProducto(props, secciones) {
    const classNames = { ...sectionStyle.mrAuto, ...sectionStyle.mlAuto }
    const title = seccionsTitle[selectedNodeId]
    let descrip = ""
    if (
      props?.descripcion &&
      props?.descripcion?.data &&
      props?.descripcion?.data?.descripcion
    ) {
      descrip = props?.descripcion?.data?.descripcion
    }

    return (
      <>
        <GridContainer justify="center">
          <GridItem md={12}>
            <Typography
              align="center"
              variant="h6"
              sx={{ paddingTop: "15px", color: "#818181" }}
            >
              {title}
            </Typography>
          </GridItem>
          {selectedNodeId === "QSOM" ? (
            <GridItem md={10} sm={10} className={classNames}>
              <br />
              <br />
              <NewDiv
                dangerouslySetInnerHTML={{
                  __html: descrip,
                }}
              />
            </GridItem>
          ) : null}
        </GridContainer>

        <GridContainer justify="center">
          <GridItem md={10} sm={10} className={classNames}>
            {secciones.map((node, index) => (
              <>
                {node.imagen_seccion !== null ? (
                  <SectionCoberturas
                    key={index}
                    image={
                      node?.imagen_seccion
                        ? node?.imagen_seccion.localFile?.childImageSharp
                            ?.gatsbyImageData?.images?.fallback?.src
                        : null
                    }
                    title={node?.nombre_seccion}
                    description={node?.texto_seccion?.data?.texto_seccion}
                    derecha={!!(index % 2)}
                  />
                ) : (
                  <InfoArea
                    key={`${index}_acc`}
                    className={sectionStyle.infoArea}
                    icon={null}
                    title={node?.nombre_seccion}
                    description={node?.texto_seccion?.data?.texto_seccion}
                    iconColor="primary"
                    image={null}
                  />
                )}
                <br />
              </>
            ))}
          </GridItem>
        </GridContainer>
      </>
    )
  }

  return selectedNodeId ? (
    <LandingPage pageTitle="Información Corporativa">
      <Parallax
        small
        image={
          selectedNodeId
            ? informacionObj?.find(
                (inf) => inf?.node?.codigo === selectedNodeId
              )?.node?.imagen?.localFile?.childImageSharp?.gatsbyImageData
                ?.images?.fallback?.src
            : ""
        }
      >
        <div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}></GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <SectionTemplate>
        {tabProducto(selectedInfo?.node, selectedSeccion)}
      </SectionTemplate>
    </LandingPage>
  ) : null
}

export const query = graphql`
  {
    allStrapiInformacionCorporativas: allStrapiInformacionCorporativa {
      totalCount
      edges {
        node {
          descripcion {
            data {
              descripcion
            }
          }
          imagen {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 95, layout: FULL_WIDTH)
              }
            }
          }
          codigo
          nombre
          orden
          id
        }
      }
    }
    allStrapiSeccionesInfoCorporativas: allStrapiSeccionesInfoCorporativa {
      nodes {
        id
        nombre_seccion
        orden
        texto_seccion {
          data {
            texto_seccion
          }
        }
        informacion_corporativa {
          codigo
          id
          nombre
          orden
        }
        imagen_seccion {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`

export default InformacionCorporativaTemplate
